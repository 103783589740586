body {
  background-color: white;
  font-size: 16px;
}

.hr-black {
  border-bottom: 2px solid black;
}

/*home page styles*/

.red-shadow {
  box-shadow: 15px 15px 10px #fba19d;
}

.red-brightest {
  background: #f95c55;
}

.home-img-col {
  padding-right: 0 !important;
}

@media only screen and (max-width:550px) {
  .home-left-col {
      padding-bottom: 20px;
  }
}

.last-row {
  padding-bottom: 20px;
}

.red-underline-text {
  text-decoration: underline #f95c55;
}

/*footer*/

.center-pills li {
  display: inline-block;
  list-style: none;
  padding-right: 1%;
  padding-top: 1%
}

.center-pills span {
  color: grey;
}

.center-pills span:hover {
  color: black;
}

/*hover.css modified*/

.hvr-grow {
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  transform: scale(1.1);
}

/*page img with button*/

.page-img {
  position: relative;
  width: 100%;
}

.page-img .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* overright some bootstrap classes */
/*navbar*/
.navbar {
  background: rgba(0,0,0,0.8);
  margin-bottom: 1em;
}
.navbar-dark  .nav-item > a:hover,
.navbar-dark .navbar-brand > a:hover{
  color: white !important;
  text-decoration: none;
}
.navbar-dark .nav-item > a,
.navbar-dark .navbar-brand > a{
  color: #dadada !important;
}

.usernameInput {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid black;
  outline: none;
  padding-bottom: 15px;
  text-align: center;
  width: 400px;
  font-size: 200%;
  letter-spacing: 3px;
}

[data-letters-left]:before {
content:attr(data-letters-left);
display:inline-block;
font-size:1em;
width:2.5em;
height:2.5em;
line-height:2.5em;
text-align:center;
border-radius:50%;
background: #9dc1fb;
vertical-align:middle;
margin-right:1em;
color:white;
}

[data-letters-right]:after {
  content:attr(data-letters-right);
  display:inline-block;
  font-size:1em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background: #fba19d;
  vertical-align:middle;
  margin-left:1em;
  color:white;
  }

li.nostyle {
  list-style-type: none;
}

.qr-contact-code {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width:550px) {
  .qr-contact-code {
      padding-top: 20px;
  }
}

.intro-calendly-link {
  background: none;
	color: #0d6efd;
	border: none;
	padding: 0;
	font: inherit;
	cursor: auto;
	outline: inherit;
  text-decoration: underline;
}

.center {
  text-align: center;
}

.red {
  color: #f95c55
}

.social-links a:hover {
  color: #f95c55
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.zpo-alexa-background {
  background-color: #dadada;
}

.display-linebreak {
  white-space: pre-line;
}